import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import JotformEmbed from 'react-jotform-embed'

import Layout from "../layouts/layout.js";
import Seo from "../components/seo.js";

const QuotePage = () => (
  <Layout>
    <Seo
      title="Get Free Quote"
    />

    <div className="relative z-0" style={{ display: "grid", background: "#000000" }}>
      <StaticImage
        style={{
          gridArea: "1/1",
          objectFit: "cover",
          opacity: "0.5",
          height: 800,
        }}
        alt="Contact Us"
        src="../img/slider1.jpg"
        formats={["auto", "webp", "avif"]}
        width={1920}
      />
      <div
        style={{
          gridArea: "1/1",
          position: "relative",
          placeItems: "center",
          display: "grid",
        }}
      >
        <div className="w-11/12 max-w-screen-md mx-auto">
          <JotformEmbed src="https://form.jotform.com/240036477207150" />
        </div>
      </div>
    </div>
  </Layout>
);

export default QuotePage